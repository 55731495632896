import React from "react";
import styled from "styled-components";

import { paddingSmall, device, colors } from "../variables";
import content from "../content/account-activation";

const AccountActivation = ({ language }) => {
  document.title = "Chess Universe | Account Activation";
  const imageSrc = require("../images/Activation_" + language + ".jpg");

  return (
    <AccountActivationWrapper>
      <Wrapper>
        <ContentWrapper>
          <div>
            <Element>{content[0][language]}</Element>
            <Element>{content[1][language]}</Element>
          </div>
          <Image src={imageSrc} />
        </ContentWrapper>
      </Wrapper>
    </AccountActivationWrapper>
  );
};

const AccountActivationWrapper = styled.div`
  max-height: 1000px;
  height: calc(100vh - 200px);
  background-color: rgb(85, 85, 85);
`;

const Wrapper = styled.div`
  position: absolute;
  height: calc(100vh - 200px);
  top: 100px;
  left: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: 100px ${paddingSmall};
  text-align: center;
  width: 100%;
  @media ${device.laptop} {
    padding: 100px 0;
  }
`;

const Element = styled.div`
  font-family: NotoSansDisplay;

  &:first-child {
    margin-bottom: ${paddingSmall};
  }

  &:not(:first-child) {
    color: ${colors.yellow};
    text-transform: uppercase;
  }
`;

const Image = styled.img`
  margin-top: 40px;
  width: 100%;

  @media ${device.laptop} {
    max-width: 550px;
  }
`;

export default AccountActivation;
