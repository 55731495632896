import React, { useEffect } from "react";

const TermsAppleArcade = () => {
  useEffect(() => {
    window.location.assign(
      "/terms-of-service-aa.html"
    );
  });

  return <></>;
};

export default TermsAppleArcade;
