import React, { useEffect } from "react";

const PrivacyAppleArcade = () => {
  useEffect(() => {
    window.location.assign(
      "/privacy-policy-aa.html"
    );
  });

  return <></>;
};

export default PrivacyAppleArcade;
