import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.location.assign(
      "/terms-of-service.html"
    );
  });

  return <></>;
};

export default Terms;
