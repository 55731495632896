import React from "react";
import styled from "styled-components";

import Heading from "./shared/Heading";
import { paddingSmall, paddingMedium, device } from "../variables";

const Home = () => {
  return (
    <HomeWrapper>
      <BackgroundImage src={require("../images/cover.jpg")}></BackgroundImage>
     <PartnerWrapper>
       <PartnerText>Partners</PartnerText>
        <PartnersList>
     
           <Partner src={require("../images/cu_pe220x67_trans_v3.png")} />
          
         
            <Partner src={require("../images/chessaca.png")} />
         
        </PartnersList>
      </PartnerWrapper> 
      <Wrapper>
        <LogoWrapper>
            
          <Logo src={require("../images/logo_chess_universe.svg")} />
                  
        </LogoWrapper>
        <ContentWrapper>
          <TextWrapper>
            <Heading fontSizeMobile="20px">
              "Reimagining the chess experience."
            </Heading>
          </TextWrapper>
          <ImagesWrapper>
            <ImageContainer>
              <a
                href="https://apps.apple.com/us/app/chess-universe/id1487204900?ls=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={require("../images/appstore.svg")} />
              </a>
            </ImageContainer>
            <ImageContainer>
              <a
                href="https://play.google.com/store/apps/details?id=com.kingsofgames.chessuniverse&utm_source=cuweb&utm_campaign=badgeclick"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={require("../images/googleplay.svg")} />
              </a>
            </ImageContainer>
          </ImagesWrapper>
        </ContentWrapper>
      </Wrapper>
    </HomeWrapper>
  );
};

//calc(100vh - 100px - 100px);

const HomeWrapper = styled.div`
  height: calc(100vh - 200px);
`;

const BackgroundImage = styled.img`
  position: relative;
  top: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const Wrapper = styled.div`
  position: absolute;
  height: calc(100vh - 200px);
  top: 100px;
  left: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 100px ${paddingSmall} ${paddingSmall} ${paddingSmall};

  @media ${device.laptop} {
    padding: calc(${paddingMedium} + 100px) ${paddingSmall} ${paddingMedium}
      ${paddingSmall};
  }

  @media ${device.desktop} {
    padding: calc(${paddingMedium} + 230px) ${paddingSmall} 230px
      ${paddingSmall};
  }
`;

const PartnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 100px;
  left: 0;
  z-index: 4;
  padding: 4px ${paddingSmall};
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    padding: 4px ${paddingMedium};
    flex-direction: row;
  }
`;

const PartnersList = styled.div`
  display: flex;
`;

const PartnerText = styled.div`
  font-size: 16px;
  padding-top: 10px;

  @media ${device.laptop} {
    padding-top: 0;
  }
`;

const Partner = styled.img`
  height: 50px;
  padding-top: 10px;

  @media ${device.laptop} {
    height: 60px;
  }
`;

const LogoWrapper = styled.div`
  z-index: 3;
  padding-top: 30px;
`;

const Logo = styled.img`
  width: 150px;

  @media ${device.laptop} {
    width: 225px;
  }

  @media ${device.desktop} {
    width: 325px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 700px;
`;

const TextWrapper = styled.div``;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 60px;
  @media ${device.laptop} {
    margin-left: 20px;
  }
`;

const ImageContainer = styled.div`
  &:not(:last-child) {
    @media ${device.laptop} {
      margin-bottom: 20px;
      width: 174px;
    }
  }
`;

const Image = styled.img`
  width: 150px;
  @media ${device.laptop} {
    width: 174px;
  }
`;

export default Home;
